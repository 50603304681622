define("webapp/pods/modules/events/event/index/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    model: function model() {
      var eventItem = this.modelFor('modules.events.event');
      var tourLocation = eventItem.get('tourLocations.firstObject');

      var model = _actionModel.default.create();

      model.set('content', {
        title: '',
        eventType: '',
        beginning: null,
        end: null,
        unitKey: '',
        cShortDescription: '',
        city: Ember.get(tourLocation, 'city'),
        zipCode: Ember.get(tourLocation, 'zipCode'),
        street: Ember.get(tourLocation, 'street'),
        longitude: Ember.get(tourLocation, 'longitude'),
        latitude: Ember.get(tourLocation, 'latitude'),
        cEditorUserId: null,
        cOrganizingUserId: null,
        cSecondOrganizingUserId: null,
        cWithoutTime: false,
        cRegistrationType: 'None',
        cExternalRegistrationUrl: '',
        cUrl: '',
        cExternalEvent: false,
        cIsBvRelevant: false,
        cLedByTourGuide: false
      });
      model.fill(eventItem);
      model.setProperties({
        city: Ember.get(tourLocation, 'city'),
        zipCode: Ember.get(tourLocation, 'zipCode'),
        street: Ember.get(tourLocation, 'street'),
        longitude: Ember.get(tourLocation, 'longitude'),
        latitude: Ember.get(tourLocation, 'latitude')
      });
      model.applyBufferedChanges();
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'eventItem', this.modelFor('modules.events.event'));
    }
  });

  _exports.default = _default;
});